<template>
    <footer class="footer">
    <div class="footer-container">
       <div class="item-logo">
            <img :src="require('@/assets/images/mar-holiday-white.png')" alt="logo marholiday">            
       </div>
       
       <div class="item-menu">
            <ul>                
                <li><a :href="$t('headerFooter.linkHome')"  target="_blank" > {{ $t('headerFooter.home') }} </a></li>
                <li><a :href="$t('headerFooter.linkAboutUs')"  target="_blank" >{{ $t('headerFooter.aboutUs') }}</a></li>
                <li><a :href="$t('headerFooter.linkExperiences')"  target="_blank" >{{ $t('headerFooter.experiences') }}</a></li>                               
            </ul>
       </div>
       <div class="item-social">
        <div class="item-social__icon">
            <FacebookIcon/>
        </div>        
       </div>
       <div class="item-contact">
            <p> USA & CAN: 1 800 728 90 62</p>
            <p> MEX: (984) 980 0529 </p>
            <p> experiences@marholidayscaribe.com</p>
       </div>
       <div class="item-address">
            <p>Acceso Xcalacoco S/N, El Limonar 1,</p>
            <p>Playa del Carmen,Quintana Roo. 77710</p>
       </div>
       <div class="item-copy">
        <p>{{ $t('headerFooter.copyRightText', {year: new Date().getFullYear() }) }} </p>
       </div>
    </div>
    </footer>
</template>

<script>
 
 import FacebookIcon from '@/modules/shop/components/icons/Fb'
 import TwitterIcon from '@/modules/shop/components/icons/Twitter'
 import InstagramIcon from '@/modules/shop/components/icons/Instagram'

  export default {       
    computed:{
      navigation(){
        return {
          home: process.env.VUE_APP_NAVIGATION_HOME_LINK || '#',
          aboutUs: process.env.VUE_APP_NAVIGATION_ABOUT_US_LINK || '#',
          experiences: process.env.VUE_APP_NAVIGATION_EXPERIENCES_LINK || '#'
        }
      } 
    },
    components:{  
        FacebookIcon,
        TwitterIcon,
        InstagramIcon,
    },    
  }
</script>

<style lang="scss" scoped>
.footer{            
    font-family: Century Gothic, Regular;    
    background-color: #0068B4;
    // height: auto;    
    color: white;       
    // margin-top: auto; 
    box-shadow: 0 50vh 0 50vh #0068B4;
}

.footer-container{
    // width: 95%;
    display: flex;   
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto; /*centro de manera horizontal*/
    align-items: center;
    // flex-wrap: wrap; /*Si los flex item no caben en la linea, creen nuevas líneas*/
    gap: 1rem;
    bottom: 0;
}

.item-logo img{
    width: 200px;
    max-width: 250px;
}

.item-menu{    
    text-align: center;
}

.item-menu ul li{
    display: inline-block;
    // line-height: 20px;  
    list-style: none;  
    // text-align: left;
}

.item-menu ul li a{
    color: #fff;
    margin-right: 1.5rem;
    font-weight: 600;
}

.item-social__icon svg{
    // position: relative;
    background-color: #fff;
    width: 28px;
    border: 1px solid rgb(214, 209, 209);
}

.item-contact{
    text-align: center;
}

.item-address{
    text-align: center;
}

.item-contact p, .item-address p{
    margin: 0;
    font-size: .8rem;

}

.item-copy{
    text-align: center;
    font-size: 1rem;    
}


@media (min-width:768px){
    .footer-container{
        width: 90%;
        max-width: 1300px; /*Para evitar que se haga más grandes que tienen más pixeles*/
        overflow: hidden; /*Para que todo lo que salga en este elemento se oculte*/
        margin: 0 auto; /*centro de manera horizontal*/
        padding: 20px 20px; /*20px arriba/abajo y 20px de izq/derecha*/
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // gap: 2rem;
        flex-wrap: wrap; /*Si los flex item no caben en la linea, creen nuevas líneas*/
    } 
    .item-logo{
        order: 1;
        width: 45%;
    }
    .item-social{
        order: 2;
        width: 45%;
        text-align: right;
    }

    .item-menu{
        order: 3;
        width: 100%;
        text-align: center;
    }

    .item-contact{
        order: 4;
        width: 45%;
        font-weight: 600;
        text-align: left;

    }
    .item-address{
        order: 5;
        width: 45%;
        text-align: right;
        font-weight: 600;

    }

    .item-copy{
        order: 6;
        width: 100%;
        text-align: center;
    }
}

@media (min-width:1200px){
   
    .item-logo{
        order: 1;
        width: 20%;
    }
    

    .item-menu{
        order: 2;
        width: 50%;
        text-align: center;
        font-size: 1.2rem;

    }

    .item-contact{
        order: 3;
        width: 20%;
        font-weight: 600;
        text-align: right;

    }
    .item-contact p{
        font-size: 1rem;

    }

    .item-social{
        order: 4;
        width: 25%;
        text-align: left;
    }
    .item-copy{
        order: 5;
        width: 45%;
        text-align: center;
        font-size: 1rem;

    }
    .item-address{
        order: 6;
        width: 25%;
        text-align: right;
        font-weight: 600;

    }
    .item-address p{
        font-size: 1rem;
    }

}
    


</style>