<template>
    <header class="header" id="header">
    <!-- Referencia de ejemplo para este menu navigation
    https://codepen.io/syrizaldev/pen/yLpKZvz
     -->
        <nav class="navbar container">
           
           <div class="nav-logo">
                <a :href="$t('headerFooter.linkHome')"><img :src="require('@/assets/images/mar-holiday.png')" alt="logo marholiday"></a>                
                <!-- <a href="#" target="_blank"><img :src="require('@/assets/images/mar-holiday.png')" alt="logo marholiday"></a>                 -->

            </div> 
           <div class="burger" id="burger">
              <span class="burger-line"></span>
              <span class="burger-line"></span>
              <span class="burger-line"></span>
           </div>
           <span class="overlay"></span>
           <div class="menu" id="menu">
              <ul class="menu-inner">
                <!-- <li class="menu-item"><a class="menu-link" :href="$t('headerFooter.linkHome')" target="_blank">{{ $t('headerFooter.home') }}</a></li> -->
                <li class="menu-item"><a class="menu-link" :href="$t('headerFooter.linkAboutUs')" >{{ $t('headerFooter.aboutUs') }} </a></li>
                <li class="menu-item"><a class="menu-link" :href="$t('headerFooter.linkExperiences')">{{ $t('headerFooter.experiences') }}</a></li>                
              </ul>
           </div>
           <div class="menu-right">
            <b-dropdown variant="link" toggle-class="p-0" no-caret right menu-class="w-100 mr-1" >
                <template #button-content>
                    <PhoneIcon class="mr-1"/>
                </template>
                <div style="width:100%; padding:10px">
                    <div class="mb-1">
                        USA/CAN <br> 1 800 728 90 62
                    </div>                                                                                  
                    <div>
                        MEX <br> (984) 980 0529
                    </div>                                                                                  
                </div>
            </b-dropdown>     
            <div >
              <button class="myBtn" id="myBtn">
                <img :src="flagLanguage" alt="logo marholiday" height="20px" width="20px" style="margin-right: 5px;" class="img-myBtn"/>
                  {{ lang ? lang.toUpperCase() : 'En' }}        
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" id="arrow">
                    <path d="M14 5L7.5 12L1 5" stroke="#1D1929" stroke-linecap="square"/>
                  </svg>
              </button>
              <div class="myDropdown" id="myDropdown">
                <a @click="changeLanguage('en')" v-if="lang === 'es'" >                    
                  <img :src="require('@/assets/images/flags/en.png')" alt="logo marholiday" height="20px" width="20px" style="margin-right: 5px;"/>
                  EN
                </a>
                <a @click="changeLanguage('es')" v-if="lang === 'en'">
                  <img :src="require('@/assets/images/flags/es.jpg')" alt="logo marholiday" height="20px" width="20px" style="margin-right: 5px;"/>
                  ES
                </a>                 
              </div>
            </div>           
           </div>          
        </nav>
     </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
 import PhoneIcon from '@/modules/shop/components/icons/Phone'
 import { market } from '@/modules/shop/mixins/market'

export default {
    mixins:[market],
    components:{
      PhoneIcon
    },
    mounted(){
      
    },
    data() {
      return {
        locales :[
          {
            locale: 'en',
            img: require('@/assets/images/flags/en.png'),
            name: 'English',
          },
          {
            locale: 'es',
            img: require('@/assets/images/flags/es.jpg'),
            name: 'French',
          },                  
        ]
      }
    },
    computed:{
      ...mapState('auth',['lang']),
      ...mapState('auth',['queryParams','lang']), 
      currentLocale() {
        return this.locales.find(l => l.locale === this.$i18n.locale)
      }, 
      linksNavigation(){
        return {
          home: process.env.VUE_APP_NAVIGATION_HOME_LINK || '#',
          aboutUs: process.env.VUE_APP_NAVIGATION_ABOUT_US_LINK || '#',
          experiences: process.env.VUE_APP_NAVIGATION_EXPERIENCES_LINK || '#'
        }
      },
      flagLanguage(){
        const enFlag = require('@/assets/images/flags/en.png')
        const esFlag = require('@/assets/images/flags/es.jpg')
        const frFlag = require('@/assets/images/flags/fr.png')
        const ptFlag = require('@/assets/images/flags/pt.png')

        const flags = { en: enFlag, es: esFlag, fr: frFlag, pt: ptFlag}

        return this.lang ? flags[this.lang] : enFlag
      }
    },
    mounted() {
      this.menuHamburguer()                
    },
    methods:{
      ...mapMutations('auth',['setDataQueryParams','setLangApplication']),  
      ...mapMutations('shop',['setLanguageCustomer']),  

      
      changeLanguage(lang){
        const idLangs =  { en: 1, es: 2 }
        this.setLangApplication(lang)                
        this.$i18n.locale = lang
        this.setLanguageCustomer( idLangs[lang] ) //idLangs.en = 1
        this.$router.replace({ query: {...this.queryParams, lang} }).catch(() => {})
      },
      showDrop(){
        document.getElementById("myDropdown").classList.toggle("show")
      }
    }
}
</script>
<style scoped>
/* @import url('https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css'); */

*{
    --color-black: hsl(0, 0%, 10%);              
    --color-white: hsl(0, 0%, 100%);
    --color-blue-100: hsl(217, 91%, 60%);
    --color-blue-200: hsl(221, 83%, 53%);      
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    font-family: Century Gothic, Regular;

    --shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  --color: #2C6CBF;
  --gap: 0.5rem;
  --radius: 5px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


a{
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-transform: unset;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}


.container {
  max-width: 75rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}


.nav-logo{
    height: 35px;    
    width: 150px;    
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  margin: 0 auto;
  background-color: var(--color-white);
  box-shadow: var(--shadow-medium);
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  margin: 0 auto;
}

.menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: var(--color-white);
  box-shadow: var(--shadow-medium);
  transition: all 0.5s ease-in-out;
}
.menu.is-active {
  top: 0;
  left: 0;
}
.menu-inner {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  margin: 1.25rem;
}
.menu-link {
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;   
  color: #0068B4;
  transition: all 0.3s ease;
}
.menu-link:hover {
  color: var(--color-blue-200);
}
@media only screen and (min-width: 48rem) {
  .menu {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin-left: auto;
    background: none;
    box-shadow: none;
  }
  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 1.75rem;
    margin: 0 auto;
    margin-right: 3rem;
  }
  .menu-link {
    text-transform: uppercase;
    margin-right: 1rem;
  }
 
}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  order: -1;
  width: 1.75rem;
  height: auto;
  border: none;
  outline: none;
  visibility: visible;
}
.burger-line {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 2px;
  margin: 6px auto;
  transform: rotate(0deg);
  background-color: var(--color-black);
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 48rem) {
  .burger {
    display: none;
    visibility: hidden;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}
.overlay.is-active {
  display: block;
  opacity: 1;
  visibility: visible;
}

.menu-right {
  display: block;
  cursor: pointer;
  font-size: 1.35rem;
  line-height: inherit;
  color: var(--color-black);
  /* border: 1px solid #000; */
  /* width: 200px; */
  display: flex;
}

/* ddrop */

.myBtn {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: var(--gap);
  padding: 0.6rem;
  cursor: pointer;
  /* border-radius: var(--radius); */
  font-size: 1rem;
  border: none;
  /* box-shadow: var(--shadow); */
  position: relative;  
  /* color: var(--color); */
}

.img-myBtn {
  pointer-events: none;
}

.bx {
  font-size: 1.1rem;
}

.myDropdown {
  position: absolute;
  width: 89px;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  margin-top: 0.3rem;
  background: white;

  visibility: hidden;
  opacity: 0;
  transform: translateY(0.5rem);
  transition: all 0.1s cubic-bezier(0.16, 1, 0.5, 1);
}

.myDropdown a {
  display: flex;
  align-items: center;
  column-gap: var(--gap);
  padding: 0.8rem 1rem;
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.myDropdown a:hover {
  background-color: var(--color);
  color: white;
}

.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0rem);
}

.arrow {
  transform: rotate(180deg);
  transition: 0.2s ease;
}

</style>

